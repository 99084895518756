:root {
    --bs-primary-hover: #{darken(saturate($green, 20%), 10%)};
    --bs-primary-focus: #{darken($green, 10%)};
    --bs-secondary-hover: #{darken(saturate($orange, 20%), 7%)};
    --bs-secondary-focus: #{darken($orange, 7%)};
}

.magistrat {
    --bs-primary: #{$dark-green};
    --bs-primary-rgb: #{to-rgb($dark-green)};
    --bs-primary-hover: #{darken(saturate($dark-green, 25%), 5%)};
    --bs-primary-focus: #{darken($dark-green, 5%)};
    --bs-secondary: #{$orange};
    --bs-secondary-rgb: #{to-rgb($orange)};
    --bs-secondary-hover: #{darken(saturate($orange, 20%), 7%)};
    --bs-secondary-focus: #{darken($orange, 7%)};
    --bs-link-color: #{$orange};
    --bs-link-color-rgb: #{to-rgb($orange)};
    --bs-link-hover-color: #{darken(saturate($orange, 20%), 7%)};
    --bs-link-hover-color-rgb: #{to-rgb(darken(saturate($orange, 20%), 7%))};
}

.aspirant {
    --bs-primary: #{$dark-orange};
    --bs-primary-rgb: #{to-rgb($dark-orange)};
    --bs-primary-hover: #{darken(saturate($orange, 20%), 7%)};
    --bs-primary-focus: #{darken($orange, 7%)};
    --bs-secondary: #{$green};
    --bs-secondary-rgb: #{to-rgb($green)};
    --bs-secondary-hover: #{darken(saturate($green, 20%), 10%)};
    --bs-secondary-focus: #{darken($green, 10%)};
    --bs-link-color: #{$green};
    --bs-link-color-rgb: #{to-rgb($green)};
    --bs-link-hover-color: #{darken(saturate($green, 20%), 10%)};
    --bs-link-hover-color-rgb: #{to-rgb(darken(saturate($green, 20%), 10%))};
}

.courses {
    --bs-primary: #{$orange};
    --bs-primary-rgb: #{to-rgb($orange)};
    --bs-primary-hover: #{darken(saturate($orange, 20%), 7%)};
    --bs-primary-focus: #{darken($orange, 7%)};
    --bs-secondary: #{$green};
    --bs-secondary-rgb: #{to-rgb($green)};
    --bs-secondary-hover: #{darken(saturate($green, 20%), 10%)};
    --bs-secondary-focus: #{darken($green, 10%)};
    --bs-link-color: #{$green};
    --bs-link-color-rgb: #{to-rgb($green)};
    --bs-link-hover-color: #{darken(saturate($green, 20%), 10%)};
    --bs-link-hover-color-rgb: #{to-rgb(darken(saturate($green, 20%), 10%))};
    --relevant-bg: #{$white};
    --teaching-bg: #{$white};
    --teaching-card-bg: #{$light};
    --outcomes-bg: #{$white};
    --passings-bg: #{$light};
}
