.passings {
    $border-width: 2px;
    $bg-color: var(--passings-bg, $white);
    background-color: $bg-color;
    @extend %section-padding;

    & .section-title {
        text-align: center;
    }

    &-wrap {
        overflow: hidden;
        margin-right: -.5rem;
        margin-left: -.5rem;
    }

    &-row {
        @extend .row;
        @extend .g-0;
        counter-reset: step;
        @include padding-x(.5rem);
        padding-top: .5rem;
        margin-bottom: -$border-width;
    }

    &__item {
        @extend .col-12;
        @extend .col-md;
        display: flex;
        counter-increment: step;

        & .passing-card {
            position: relative;
            width: 100%;
            border-style: solid;
            border-color: var(--bs-primary);
            border-width: 0 0 $border-width $border-width;

            & .card-title {
                position: relative;
                @extend h5;
                @include back-color(var(--bs-primary));
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                padding: .5rem 1em;
                margin: 0;
            }

            & .card-body {
                display: flex;
                align-items: center;
                line-height: 1.4;
                padding: 1.25rem;

                &:before {
                    content: "0" counter(step);
                    @extend h1;
                    color: var(--bs-primary);
                    align-self: flex-start;
                    width: 1.5em;
                    line-height: 1;
                    flex-shrink: 0;
                    margin-right: .5rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        & .passing-card {
            padding-top: 1.25rem;

            &:before {
                content: '';
                @include circle(5px);
                background-color: var(--bs-primary);
                position: absolute;
                top: -$border-width * .5;
                left: -$border-width * .5;
                transform: translate(-50%, -50%);
            }

            & .card-title {
                white-space: nowrap;
                @include padding-y(.65rem);
                @include padding-x(1.25rem, 2rem);

                &:before {
                    content: '';
                    display: block;
                    border-style: solid;
                    border-color: transparent $bg-color transparent transparent;
                    border-width: 1.2em 1.2em;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        & .passing-card {
            min-width: 400px;
            padding-right: 1.5rem;
        }
    }

    @include media-breakpoint-down(sm) {
        & .section-title {
            margin-bottom: 1rem;
        }

        & .passing-card {
            & .card-title {
                font-size: 1rem;
            }

            & .card-body {
                @include padding-x(1rem);
            }
        }
    }
}
