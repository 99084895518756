.svg-icon {
    width: 1.25em;
    height: 1.25em;
}

.unstyled {
    @extend %unstyled;
}

.circle {
    @extend .list-unstyled;

    & li {
        position: relative;
        line-height: 1.4;
        padding-left: 1.25em;
        margin-bottom: .5rem;

        &:before {
            content: '';
            @include circle(.5em);
            background-color: var(--bs-secondary);
            position: absolute;
            left: 0;
            top: .5em;
        }

        & a {
            color: $body-color;
            @extend .text-decoration-none;
            transition: $color-transition;

            &:hover {
                color: var(--bs-secondary)
            }
        }
    }
}

.topics-list {
    @extend .list-unstyled;

    & li {
        display: block;
        background-color: $gray-300;
        padding: .75em 1.25em;
        font-weight: $font-weight-bold;
        line-height: 1.4;
        @extend .rounded;
        margin-bottom: .5em;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 1.25rem;
    }
}
