// Options

$enable-shadows: false;
$enable-gradients: false;
$enable-responsive-font-sizes: true;
$enable-validation-icons: false;
$enable-caret: false;

// Grid containers

$container-max-widths: (
  lg: 960px,
  xl: 1200px,
  xxl: 1400px,
);

$grid-gutter-width: 2rem;

// Spacing

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
);

// Color system

$white:    #fff;
$gray-100: #F3F3F3;
$gray-200: #e9ecef;
$gray-300: #E0E0E0;
$gray-400: #D9D9D9;
$gray-500: #A3A3A3;
$gray-600: #818181;
$gray-700: #5A5A5A;
$gray-800: #343a40;
$gray-900: #2F2F2F;
$black:    #000;

$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #E24E25;
$yellow:  #ffc107;
$green:   #358E42;
$teal:    #20c997;
$cyan:    #2AABEE;

$dark-layout: #0D171E;
$dark-orange: #CB2E03;
$dark-green: #356044;

$primary:       $green;
$secondary:     $orange;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

// Body

$body-bg:       $white;
$body-color:    $gray-900;

// Links

$link-color:                  $secondary;
$link-decoration:             underline;
$link-hover-decoration:       none;

// Typography

$base-font-path:              '../fonts';
$base-font-name:              'Roboto';
$font-family-base:            $base-font-name, sans-serif;
$line-height-base:            1.5;

$font-size-base:              1rem;
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            500;
$font-weight-bolder:          700;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-font-weight:        700;
$headings-line-height:        1.3;
$headings-margin-bottom:      1.5rem;

$lead-font-size:              $font-size-base * 1.25;
$lead-font-weight:            $font-weight-light;
$display-font-weight:         $font-weight-bolder;

$small-font-size:             .825em;

// Components

$border-radius:               .625rem;
$border-radius-sm:            .5rem;
$border-radius-lg:            1.5rem;
$color-transition:            color .15s ease-in-out;

// Buttons + Forms

$input-btn-font-size: 1em;
$input-btn-padding-y: .65em;
$input-btn-font-size-sm: 1rem;
$input-btn-padding-y-sm: .5em;
$input-btn-font-size-lg: 1.25rem;
$input-btn-padding-y-lg: .85rem;
$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: none;

$btn-padding-x: 1.5rem;
$btn-padding-x-sm: 1.25rem;
$btn-padding-x-lg: 1.5em;
$btn-border-radius-lg: $border-radius;
$btn-focus-width: 0;
$btn-hover-bg-shade-amount: 10%;
$btn-close-focus-shadow: none;

$input-padding-x: 1em;
$input-padding-x-lg: 1em;
$input-padding-x-sm: 1rem;
$input-border-color: $gray-600;
$input-placeholder-color: $gray-500;
$input-focus-border-color: $body-color;
$form-select-focus-box-shadow: none;
$form-check-input-checked-bg-color: $secondary;
$form-check-input-border: 1px solid $gray-600;
$form-check-input-focus-border: $gray-600;
$form-switch-focus-color: $gray-600;
$form-check-input-focus-box-shadow: none;

// Navbar

$navbar-light-color: $body-color;
$navbar-light-hover-color: $link-color;
$navbar-light-active-color: $link-color;
$navbar-nav-link-padding-x: 1rem;
$navbar-padding-y: .75rem;
$navbar-padding-x: 1rem;
$navbar-light-toggler-border-color: transparent;
$navbar-light-icon-color: $body-color;
$navbar-toggler-padding-x: .5rem;
$navbar-toggler-font-size: 1.35rem;

// Navs

$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-border-color: $primary;

// Figures

$figure-caption-font-size: 1em;
$figure-caption-color: $body-color;

// Cards

$card-max-width: 380px;
$card-border-width: 0;
$card-border-radius: 0;
$card-spacer-y: 1.25rem;
$card-spacer-x: 0;
$card-cap-padding-y: 0;
$card-cap-bg: transparent;

// Modals

$modal-content-border-width: 0;
$modal-header-border-width: 0;
$modal-content-border-radius: $border-radius;
