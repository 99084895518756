.application {
    @include back-color(var(--bs-primary));
    @include padding-y(2rem);

    &-row {
        @extend .row;
        @extend .g-3;
        align-items: center;
        margin-bottom: 1rem;
    }

    &-col {
        @extend .col-12;
        @extend .col-md;
    }

    & .form-control {
        @extend .form-control-xl;
    }

    & .form-check-input:not(:checked),
    & .form-control {
        border-color: $input-bg;
    }

    & .form-submit {
        @extend .col-12;
        @extend .col-sm-auto;

        & .btn {
            @extend .btn-secondary;
            @extend .btn-xl;
            @include padding-x(2em);
        }
    }

    & .form-checkbox {
        @extend .col-12;
        @extend .col-sm;
        font-size: $small-font-size;
        @extend .order-sm-1;
    }

    @include media-breakpoint-down(sm) {
        & .form-submit {
            & .btn {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        @include padding-y(3rem);
    }

    @include media-breakpoint-up(xxl) {
        @include padding-y(4rem);
    }
}
