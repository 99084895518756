html {
    font-size: 16px;
}
body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(xxl) {
        font-size: 1.125rem;
    }
}
main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}
img {
    max-width: 100%;
}
a, button {
    outline: 0 !important;
}
