.btn-primary {
    --bs-btn-color: #{$white};
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: var(--bs-primary-hover);
    --bs-btn-hover-border-color: var(--bs-primary-hover);
    --bs-btn-active-color: #{$white};
    --bs-btn-active-bg: var(--bs-primary-focus);
    --bs-btn-active-border-color: var(--bs-primary-focus);
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: var(--bs-primary);
    --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-secondary {
    --bs-btn-color: #{$white};
    --bs-btn-bg: var(--bs-secondary);
    --bs-btn-border-color: var(--bs-secondary);
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: var(--bs-secondary-hover);
    --bs-btn-hover-border-color: var(--bs-secondary-hover);
    --bs-btn-active-color: #{$white};
    --bs-btn-active-bg: var(--bs-secondary-focus);
    --bs-btn-active-border-color: var(--bs-secondary-focus);
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: var(--bs-secondary);
    --bs-btn-disabled-border-color: var(--bs-secondary);
}

.btn-xl {
    @include media-breakpoint-up(xl) {
        font-size: $btn-font-size-lg;
        padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
}
