.certificate {
    @extend .bg-light;
    border-radius: $border-radius;
    padding: 1.25rem;

    & .card-row {
        @extend .row;
        @extend .g-4;
        @extend .g-lg-5;
    }

    & .card-col {
        @extend .col-12;
        @extend .col-md;
        display: flex;
        flex-direction: column;
    }

    & .card-image {
        @extend .rounded;
        width: 100%;
    }

    & .card-text {
        flex-grow: 1;
    }

    & .card-footer {
        color: $gray-600;
        font-size: $small-font-size;
        padding-top: 1rem;
        @extend .border-top;
    }

    &-item {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        & .svg-icon {
            flex: 0 0 auto;
            align-self: flex-start;
            width: 60px;
            height: 60px;
            color: var(--bs-primary);
            margin-right: 1rem;
        }

        & .item-text {
            font-weight: $font-weight-bold;
            line-height: 1.4;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }

    @include media-breakpoint-up(xl) {
        border-radius: $border-radius-lg;
        padding: 2.5rem;

        &-item {
            margin-bottom: 1.5rem;

            & .svg-icon {
                width: 80px;
                height: 80px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        & .card-col {
            padding: 1rem 2rem;
        }
    }

    @include media-breakpoint-down(xl) {
        font-size: $font-size-sm;
    }
}
