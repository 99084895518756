.auth {
    &-modal {
        @extend .modal-dialog-centered;

        & .modal-header {
            text-align: center;
            flex-direction: column;
            align-items: center;

            & img {
                width: 80px;
                margin-bottom: .5rem;
            }

            & .btn-close {
                position: absolute;
                top: 0;
                right: 0;
                margin: .5rem;
            }
        }

        & .modal-content {
            @extend .py-3;
            @extend .px-sm-3;
        }

        & .form-label {
            font-size: $small-font-size;
        }

        & .form-submit {
            margin-top: 2rem;

            & .btn {
                width: 100%;
                margin-bottom: 1em;
            }

            & .btn-outline {
                border-color: $body-color;

                &:hover, &:focus {
                    @include back-color(var(--bs-primary));
                    border-color: var(--bs-primary);
                }
            }

            & .assent-text {
                font-size: $form-text-font-size;

                & a {
                    @extend .text-body;
                }
            }
        }

        & .forgot-password {
            font-size: $font-size-sm;

            & a {
                @extend .text-body;
            }
        }

        & .alert {
            font-size: .9em;
            @include padding-y(.75rem);
        }
    }

    &-footer {
        font-size: $font-size-base;
        text-align: center;
        padding-top: 1.5rem;

        & a {
            @extend .text-body;
        }
    }

    @include media-breakpoint-down(sm) {
        &-modal {
            & .modal-header {
                & img {
                    width: 60px;
                }
            }
        }
    }
}
