%card-image {
    position: relative;
    aspect-ratio: 7 / 4;
    border-radius: $border-radius;
    overflow: hidden;

    & img {
        @include img-cover();
    }

    & .image-layout {
        position: absolute;
        inset: 0;
        @include gradient-y(rgba($black, .8), transparent);
        color: $white;
        padding: 1.5rem;

        & .badge-nmo {
            display: inline-block;
            @include back-color($white, var(--bs-primary));
            font-weight: $font-weight-bold;
            font-size: $font-size-sm;
            line-height: 1;
            padding: .7rem 1rem;
            border-radius: $border-radius-lg;
            white-space: nowrap;
        }

        & .card-date {
            font-weight: $font-weight-bold;
            font-size: $font-size-lg;
        }
    }

    @include media-breakpoint-up(lg) {
        border-radius: $border-radius-lg;
    }
}

.card {
    @extend %unstyled;
    width: 100%;
}

.course-card {
    @extend .card;

    & .card-image {
        @extend %card-image;
    }

    & .card-title {
        @extend h5;
        margin: 0;
    }

    & .params-tags {
        @extend .list-unstyled;
        margin: 0;

        & li {
            display: inline-block;
            white-space: nowrap;
            padding: 0.5rem 1rem;
            border-radius: $border-radius-pill;
            border: 1px solid var(--bs-primary);
            @extend .mb-1;

            @include media-breakpoint-down(sm) {
                font-size: $font-size-sm;
            }
        }
    }
}
