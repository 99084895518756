.direction {
    @extend .page-section;

    &-label {
        @extend h4;
        text-align: center;
        margin-bottom: 1rem;
        @extend .mb-xl-4;
    }

    & .nav-pills {
        margin-bottom: 2rem;
        @extend .mb-lg-6;

        & .nav-link {
            flex: 1 0 0%;
            @include flex();
            text-align: center;
            font-weight: $font-weight-bold;
            @extend %lead-text;
            @extend .py-lg-3;
            @extend .py-xxl-4;

            & ~ .nav-link {
                @extend .ms-sm-2;
                @extend .ms-md-3;
            }
        }

        @include media-breakpoint-down(md) {
            & .nav-link {
                font-size: $font-size-sm;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;

            & .nav-link {
                min-height: 60px;

                & ~ .nav-link {
                    margin-top: .5rem;
                }
            }
        }
    }

    &__body {
        @extend %lead-text;

        & h4 {
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 1rem;
        }
    }
}
