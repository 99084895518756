.program {
    @extend .bg-light;
    border-radius: $border-radius;
    padding: 1.25rem;

    & .card-row {
        @extend .row;
        @extend .g-4;
        @extend .g-lg-5;

        &:not(:last-child) {
            margin-bottom: 2rem;
            @extend .mb-xxl-6;
        }
    }

    & .card-col {
        @extend .col-12;
        @extend .col-md;
        display: flex;
        flex-direction: column;

        & .btn {
            width: 100%;
        }
    }

    & .card-head {
        @extend .col-12;
        @extend .col-lg-6;
        @extend .order-lg-1;
    }

    & .card-image {
        @extend %card-image;
    }

    & .card-title {
        @extend h4;
        margin-bottom: 1rem;
    }

    & .card-text {
        margin-bottom: 2rem;
        flex-grow: 1;
    }

    & .card-params {
        @extend .row;
        @extend .gy-3;

        & .params-item {
            @extend .col-auto;
            display: flex;
            align-items: flex-start;

            & .svg-icon {
                flex: 0 0 auto;
                width: 1.75em;
                height: 1.75em;
                color: var(--bs-primary);
                margin-right: .75em;
            }

            & .param-text {
                & p {
                    font-weight: $font-weight-bold;
                    line-height: 1.3;
                    white-space: nowrap;
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding: 2rem;
    }

    @include media-breakpoint-up(xl) {
        border-radius: $border-radius-lg;
        padding: 2.5rem;
    }

    @include media-breakpoint-up(xxl) {
        padding: 3rem;
    }

    @include media-breakpoint-between(sm, lg) {
        & .card-image {
            aspect-ratio: 9 / 4;
        }
    }

    @include media-breakpoint-down(sm) {
        & .card-text {
            font-size: $font-size-sm;
        }
    }
}
