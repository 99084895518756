.form-group {
    margin-bottom: 1em;
}

.form-control {
    &::placeholder {
        transition: all .25s ease;
    }

    &:focus {
        &::placeholder {
            opacity: 0;
        }
    }

    &-xl {
        @include media-breakpoint-up(xl) {
            font-size: $input-font-size-lg;
            padding: $input-padding-y-lg $input-padding-x-lg;
        }
    }
}

.form-check {
    &-input {
        &:checked {
            background-color: var(--bs-secondary);
            border-color: var(--bs-secondary);
        }
    }
}
