%unstyled {
    color: inherit !important;
    text-decoration: none !important;
}

%scrollbar {
    @include media-breakpoint-up(xl) {
        @-moz-document url-prefix() {
            scrollbar-width: thin;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
            background-color: transparent;
            width: 6px;
        }
        &::-webkit-scrollbar:horizontal {
            background-color: transparent;
            height: 6px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $gray-500;
        }
    }
}

%section-padding {
    @include padding-y(2.5rem);

    @include media-breakpoint-up(xl) {
        @include padding-y(4rem);
    }
}

%lead-text {
    @include media-breakpoint-up(xl) {
        font-size: 1.1em;
    }
}
