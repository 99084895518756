.program-parts {
    background-color: $light;
    @extend %section-padding;
    margin-top: 2rem;

    &__head {
        text-align: center;
        margin-bottom: 2rem;

        & .section-title {
            margin-bottom: .5rem;
        }
    }

    &__body {
        @extend .row;
        @extend .g-3;
        @extend .g-lg-4;
        @extend .g-xl-5;
    }

    &__item {
        @extend .col-12;
        @extend .col-sm-6;
        display: flex;
    }

    & .parts-figure {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: $white;
        border-radius: $border-radius;
        padding: 1.25rem;
        margin: 0;
        @extend .mx-auto;
        box-shadow: 0 .25rem .5rem rgba($black, .1);

        & .svg-icon {
            flex: 0 0 auto;
            width: 60px;
            height: 60px;
            color: var(--bs-primary);
            margin-right: 1rem;
        }

        & .figure-caption {
            @extend %lead-text;
            line-height: 1.4;
        }

        & .figure-name {
            font-weight: $font-weight-bold;
        }

        @include media-breakpoint-up(lg) {
            & .svg-icon {
                width: 80px;
                height: 80px;
            }
        }

        @include media-breakpoint-up(xl) {
            border-radius: $border-radius-lg;
            padding: 2.5rem;
        }

        @include media-breakpoint-down(sm) {
            max-width: $card-max-width;
        }
    }
}
