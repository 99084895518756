.benefits {
    @extend .container;
    @extend %section-padding;

    & .section-title {
        text-align: center;
    }

    &-row {
        @extend .row;
        @extend .g-3;
    }

    &__item {
        @extend .col-12;
        @extend .col-sm-6;
        @extend .col-md-4;
        @extend .col-xl-3;
        display: flex;
    }

    & .benefit {
        width: 100%;
        @include flex();
        @include back-color(var(--bs-primary));
        font-weight: $font-weight-bold;
        text-align: center;
        line-height: 1.4;
        padding: 1.25rem;
        border-radius: $border-radius;
    }
}
