.courses {
    &-filter {
        margin-bottom: 3rem;

        & .filter {
            &-group {
                margin-bottom: 1rem;
            }

            &-tags {
                margin-bottom: 1rem;

                & .btn {
                    width: 100%;
                    white-space: nowrap;
                    @extend .px-xl-5;

                    @include media-breakpoint-down(sm) {
                        font-size: $font-size-sm;
                    }
                }

                & .tag {
                    position: relative;
                    width: 100%;
                    min-width: 160px;

                    & .btn {
                        background-color: $gray-300;
                        border-color: $gray-300;

                        &:hover {
                            background-color: $gray-400;
                        }
                    }

                    & input[type="checkbox"] {
                        position: absolute;
                        left: 0;
                        width: 1rem;
                        height: 1rem;
                        opacity: 0;
                        z-index: -1;

                        &:checked {
                            & + .btn {
                                @include back-color(var(--bs-primary));
                                border-color: var(--bs-primary);
                            }
                        }
                    }
                }

                & .btn-reset {
                    border-color: $input-border-color;

                    &:hover, &:focus {
                        @include back-color(var(--bs-primary));
                        border-color: var(--bs-primary);
                    }
                }
            }

            &-search {
                position: relative;

                & .btn-group {
                    display: flex;
                    position: absolute;
                    top: $input-border-width;
                    right: $input-border-width;
                    bottom: $input-border-width;
                    background-color: $input-bg;

                    & .btn {
                        padding: 0;
                        border: 0;
                    }

                    & .btn-reset {
                        @extend .btn-close;
                        height: auto;
                        background-size: .85rem;
                        width: 2rem;
                    }

                    & .btn-submit {
                        background-image: url(../img/icons/search.svg);
                        @include back-image($size: 1.25rem);
                        width: 3rem;
                    }
                }
            }
        }
    }

    &__row {
        @extend .row;
        @extend .gx-4;
        @extend .gx-lg-5;
    }

    &__item {
        @extend .col-12;
        @extend .col-md-6;
        @extend .col-xl-4;
        margin-bottom: 2.5rem;
        display: flex;
        @extend .mb-lg-6;
    }
}
