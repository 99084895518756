.footer {
    color: $white;
    background-image: url(../img/intro-bg.jpg);
    @include back-image($position: top center);

    &-layout {
        background-color: rgba($dark-layout, .8);
    }

    &__row {
        @extend .row;
        @extend .pt-3;
        @extend .mb-lg-3;
        align-items: center;
    }

    &__menu {
        @extend .col-12;
        @extend .col-md;
        @extend .py-3;
        @extend .d-none;
        @extend .d-md-flex;

        & .nav {
            justify-content: center;

            & .nav-link {
                @extend %unstyled;

                &:hover {
                    opacity: .8;
                }
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 1rem;
            }
        }
    }

    &__brand {
        @extend .col;
        @extend .col-lg-auto;
        @extend .py-3;

        &-name {
            font-size: $h4-font-size;
            font-weight: $font-weight-bold;
            line-height: $headings-line-height;
            max-width: 120px;
        }

        & img {
            width: 300px;
        }
    }

    &__social {
        @extend .col-12;
        @extend .col-lg-auto;
        @extend .py-3;
        @include flex();

        & .social-link {
            @extend %unstyled;
            flex: 0 0 0%;

            &:hover {
                opacity: .8;
            }

            & ~ .social-link {
                margin-left: 1rem;
            }
        }
    }

    &__copyright {
        background-color: rgba($black, .15);
        padding: 8px;
        text-align: center;
        font-size: 12px;
    }

    @include media-breakpoint-up(xxl) {
        &__brand {
            width: 420px;
        }
    }
}
