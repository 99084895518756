.semesters {
    & .nav {
        &-item {
            flex: 1 0 0%;
        }

        &-link {
            position: relative;
            @extend .text-body;
            text-align: left;
            padding: 0;

            & .semester-name {
                display: block;
                font-weight: $font-weight-bolder;
                font-size: 1.15em;
                text-transform: uppercase;
            }

            & .semester-period {
                display: block;
                font-weight: $font-weight-bold;
                font-size: .8em;
            }

            &:before {
                content: '';
                @include circle-angle($gray-300);
                @include angle-down();
                @include transition($btn-transition);
                position: absolute;
            }

            &:hover {
                &:before {
                    background-color: var(--bs-secondary);
                }
            }

            &.active {
                color: var(--bs-secondary) !important;

                &:before {
                    background-color: var(--bs-secondary);
                    @include angle-up();
                }
            }
        }
    }

    & .tabs-body {
        @include padding-y(.5rem);
        padding-left: 1rem;
        @extend .pt-lg-4;
    }

    @include media-breakpoint-up(lg) {
        & .nav {
            &-item {
                padding-top: 1.5rem;
                margin-bottom: 1.5rem;
                border-bottom: 1px solid $gray-300;
            }

            &-link {
                padding-bottom: 2rem;

                &:before {
                    left: 0;
                    bottom: 0;
                    transform: translateY(50%);
                }
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        & .nav {
            &-item {
                font-size: 1.25rem;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        border-left: 1px solid $gray-300;
        padding-left: 1rem;
        margin-left: 1rem;

        & .nav {
            flex-direction: column;

            &-item {
                margin-bottom: 1rem;
            }

            &-link {
                padding-left: 1rem;

                &:before {
                    width: 2rem;
                    height: 2rem;
                    background-size: .75rem auto !important;
                    right: 100%;
                    top: 0;
                }
            }
        }
    }
}
