.applicants {
    border-radius: $border-radius;
    background-color: $light;
    padding: 1.5rem;

    &-item {
        display: flex;
    }

    &-figure {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $white;
        border-radius: $border-radius;
        padding: 1.75rem 1.25rem;
        margin: 0;
        @extend .mx-auto;

        & .digit {
            display: flex;
            align-items: center;
            font-size: 3rem;
            font-weight: $font-weight-bolder;
            color: var(--bs-primary);
            line-height: 1;
            white-space: nowrap;
            margin-bottom: 1rem;

            & .svg-icon {
                flex: 0 0 auto;
                width: 40px;
                height: 40px;
                color: $gray-500;
                margin-right: 1rem;
            }
        }

        & .figure-caption {
            text-align: center;
        }
    }

    @include media-breakpoint-up(xl) {
        border-radius: $border-radius-lg;
        padding: 2.5rem;
    }

    @include media-breakpoint-down(md) {
        &-figure {
            @include padding-y(1.5rem);

            & .digit {
                font-size: 2rem;

                & .svg-icon {
                    width: 35px;
                    height: 35px;
                    margin-right: .75rem;
                }
            }

            & .figure-caption {
                font-size: $font-size-sm;
            }
        }
    }
}
