.table-flex {
    display: table;
    border-spacing: .5rem;

    & .table-head {
        display: table-row;

        & .table-cell {
            @include back-color(var(--bs-primary));
            font-weight: $font-weight-bold;
        }
    }

    & .table-row {
        display: table-row;
    }

    & .table-cell {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        background-color: $light;
        line-height: 1.4;
        padding: .75rem;
        @extend .px-xl-3;
        @extend .rounded;
        min-width: 150px;
    }

    @include media-breakpoint-down(md) {
        font-size: $font-size-sm;
    }
}
