.outcomes {
    background-color: var(--outcomes-bg, $light);
    @extend %section-padding;

    & .section-title {
        text-align: center;
        margin-bottom: 0;
        @extend .mb-xl-3;
    }

    &-list {
        @extend .list-unstyled;
        counter-reset: num;
        margin: 0;

        & li {
            position: relative;
            display: inline-flex;
            align-items: center;
            @extend %lead-text;
            line-height: 1.4;
            width: 100%;
            margin-top: 1.5rem;

            &:before {
                counter-increment: num;
                content: counter(num);
                @include flex();
                flex-shrink: 0;
                align-self: flex-start;
                @include back-color(var(--bs-secondary));
                border-radius: $border-radius-sm;
                @extend h2;
                width: 1.25em;
                height: 1.25em;
                line-height: 1;
                margin-bottom: 0;
                margin-right: .5em;
            }
        }

        @include media-breakpoint-up(lg) {
            column-count: 2;
            column-gap: $grid-gutter-width;

            & li {
                margin-top: 2rem;
            }
        }
    }
}
