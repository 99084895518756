.header {
    @extend .sticky-top;
    background-color: $body-bg;
}

.navbar {
    position: static;
    @extend .container;
    @extend .navbar-expand-xl;

    &-brand {
        width: 140px;
    }

    &-collapse {
        background-color: $body-bg;

        & .navbar-nav {
            @extend .container;

            & .nav-link {
                font-weight: $font-weight-bold;

                &:hover, &.active {
                    color: var(--bs-primary);
                }
            }
        }
    }

    &-action {
        margin-left: auto;

        & .btn-login {
            @extend .btn-primary;
            @extend .btn-sm;
        }
    }

    @include media-breakpoint-up(xl) {
        @include padding-y(1.5rem);

        &-brand {
            width: 160px;
        }

        &-collapse {
            & .navbar-nav {
                @include padding-x(0);
                justify-content: center;
            }

            & .nav-item {
                flex-wrap: nowrap;

                & .nav-link {
                    line-height: 1;
                    padding: 0 1.5rem;
                }

                & ~ .nav-item {
                    border-left: 1px solid $gray-500;
                }
            }
        }

        &-action {
            order: 1;
        }
    }

    @include media-breakpoint-up(xxl) {
        &-brand {
            width: 180px;
        }
    }

    @include media-breakpoint-down(xl) {
        &-collapse {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;

            & .navbar-nav {
                @include padding-y(1rem, 1.5rem);
            }
        }

        &-action {
            & .btn-login {
                margin-right: 1rem;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-brand {
            width: 120px;
        }
    }

    @include media-breakpoint-down(sm) {
        &-brand {
            width: 100px;
        }

        &-action {
            & .btn-login {
                padding: 0;
                border: 0;
                background-color: transparent !important;
                width: 1.85rem;
                height: 1.85rem;
                background-image: url(../img/icons/circle-user.svg);
                @include back-image();
                margin-right: .5rem;

                & span {
                    display: none;
                }
            }
        }
    }
}
