.nav-overflow {
    $gap: $grid-gutter-width * .5;
    position: relative;
    margin-left: -$gap;
    margin-right: -$gap;
    margin-bottom: 1.25rem;
    @extend .mb-lg-5;

    & .tabs-menu {
        position: relative;
        padding-bottom: .75rem;
        overflow-x: auto;
        overflow-y: hidden;
        @extend %scrollbar;
    }

    & .nav {
        position: relative;
        display: inline-flex;
        flex-wrap: nowrap;
        padding-left: $gap;
        padding-right: $gap;
    }
}

.tabs-menu {
    & .nav {
        &-tabs {
            min-width: 100%;
        }

        &-item {
            flex: 1 0 0%;
        }

        &-link {
            color: $gray-600;
            font-weight: $font-weight-bold;
            text-align: center;
            white-space: nowrap;
            border-bottom: 2px solid $gray-300;
            @extend .pt-0;
            @extend .pb-xxl-3;

            &:hover {
                color: $body-color;
            }

            &.active {
                color: var(--bs-primary);
                border-bottom-color: var(--bs-primary);
            }
        }

        @include media-breakpoint-down(lg) {
            &-link {
                font-size: $font-size-sm;
            }
        }
    }
}

.nav-pills {
    & .nav-link {
        color: $gray-600;
        border: 1px solid $gray-500;

        &:hover {
            color: var(--bs-primary);
            border-color: var(--bs-primary);
        }

        &.active {
            @include back-color(var(--bs-primary));
            border-color: var(--bs-primary);
        }
    }
}
