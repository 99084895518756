.relevant {
    margin-top: 1.5rem;
    background-color: var(--relevant-bg, $light);

    &__body {
        @extend .container;
        @extend %lead-text;
        @extend .py-4;

        & p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-top: 2rem;
    }
}
