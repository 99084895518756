$font-base-300: 'roboto-v30-latin_cyrillic-300';
$font-base-400: 'roboto-v30-latin_cyrillic-regular';
$font-base-500: 'roboto-v30-latin_cyrillic-500';
$font-base-700: 'roboto-v30-latin_cyrillic-700';
$fonts: (300, $font-base-300),
        (400, $font-base-400),
        (500, $font-base-500),
        (700, $font-base-700);
@each $weight, $file in $fonts {
    @font-face {
        font-family: $base-font-name;
        src: local(''),
             url('#{$base-font-path}/#{$file}.woff2') format('woff2'),
             url('#{$base-font-path}/#{$file}.woff') format('woff');
        font-weight: $weight;
        font-style: normal;
    }
}
