.intro {
    background-image: url(../img/intro-bg.jpg);
    @include back-image($position: top center);
    color: $white;
    overflow: hidden;

    &__layout {
        background-color: rgba($dark-layout, .8);
        padding-top: 1.5rem;
    }

    &-title {
        @extend .display-1;
        text-transform: uppercase;
        letter-spacing: .1rem;
        line-height: 1.2;
    }

    &-logo {
        & img {
            margin-bottom: 1.5rem;
        }
    }

    &-action {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2.5rem;

        & .btn {
            @extend .btn-primary;
            @extend .btn-xl;
            min-width: 14em;
            margin-left: auto;
        }
    }

    &__slogan {
        @include gradient-x(var(--bs-primary), transparent);
        @include padding-y(.5rem);
        font-size: 3.5vw;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        letter-spacing: .05em;
        line-height: 1.3;
        white-space: nowrap;
    }

    @include media-breakpoint-up(md) {
        &__layout {
            padding-top: 3rem;
        }

        &-title {
            letter-spacing: .1em;
        }

        &-action {
            margin-bottom: 3rem;
        }
    }

    @include media-breakpoint-up(xl) {
        &__layout {
            padding-top: 4rem;
        }

        &-logo {
            & img {
                margin-bottom: 2rem;
            }
        }

        &-action {
            margin-bottom: 4rem;
        }
    }

    @include media-breakpoint-up(xxl) {
        &-logo {
            & img {
                width: 400px;
            }
        }

        &-title {
            font-size: 7rem;
        }

        &__slogan {
            font-size: 3.4rem;
            @include padding-y(.75rem);
        }
    }

    @include media-breakpoint-down(sm) {
        &-title {
            font-size: 2rem;
        }

        &-logo {
            & img {
                width: 200px;
                margin-bottom: 1rem;
            }
        }

        &-action {
            & .btn {
                margin-right: auto;
            }
        }
    }
}
