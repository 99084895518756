.teaching {
    background-color: var(--teaching-bg, $light);
    @extend %section-padding;

    & .section-title {
        text-align: center;
    }

    &-row {
        @extend .row;
        @extend .g-3;
        @extend .g-lg-4;
        @extend .g-xl-5;
        justify-content: center;
    }

    &__item {
        @extend .col-12;
        @extend .col-sm-6;
        @extend .col-md-4;
        display: flex;

        & > a {
            @extend %unstyled;
            display: flex;
            width: 100%;
        }
    }

    &-figure {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: var(--teaching-card-bg, $white);
        border-radius: $border-radius;
        padding: 1.25rem;
        margin: 0;
        @extend .mx-auto;

        & .svg-icon {
            width: 60px;
            height: 60px;
            color: var(--bs-primary);
            margin-bottom: 1rem;
        }

        & .figure-caption {
            @extend %lead-text;
            font-weight: $font-weight-bold;
            text-align: center;
            line-height: 1.4;
        }

        @include media-breakpoint-up(lg) {
            & .svg-icon {
                width: 80px;
                height: 80px;
            }
        }

        @include media-breakpoint-up(xl) {
            border-radius: $border-radius-lg;
            padding: 2.5rem;
        }

        @include media-breakpoint-down(sm) {
            max-width: $card-max-width;
        }
    }
}
