%section {
    @extend .py-3;
    @extend .py-md-4;
    @extend .py-lg-5;
    @extend .py-xxl-6;
}

.container {
    @include media-breakpoint-between(sm, lg) {
        @include padding-x(1.5rem);
    }
}

.section {
    @extend %section;
}

.page {
    &-section {
        @extend .container;
        @extend %section;

        & .section-title {
            text-align: center;
        }
    }

    &-pagination {
        text-align: center;
        @extend .my-3;

        & .btn {
            @extend .btn-secondary;
            min-width: 200px;
        }
    }
}

.section-title {
    @extend h1;
}

.primary-title {
    @extend h1;
    text-align: center;
    @include back-color(var(--bs-primary));
    padding: 1rem;
    margin: 0;
}
