@mixin padding-x($left, $right: $left) {
    padding-left: $left;
    padding-right: $right;
}
@mixin padding-y($top, $bottom: $top) {
    padding-top: $top;
    padding-bottom: $bottom;
}
@mixin flex($justify: center, $align: center) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}
@mixin back-image($repeat: no-repeat, $position: center, $size: cover) {
    background-repeat: $repeat;
    background-position: $position;
    background-size: $size;
}
@mixin back-color($bgcolor: $primary, $color: $white) {
    background-color: $bgcolor;
    color: $color;
}
@mixin circle($size) {
    display: block;
    width: $size;
    height: $size;
    border-radius: 50%;
}
@mixin img-cover($height: 100%, $position: center) {
    width: 100%;
    height: $height;
    object-fit: cover;
    object-position: $position;
}
@mixin angle-down($color: $white) {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10' fill='none'><path stroke='#{$color}' d='M0.999999 1L8 9L15 1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>"));
    @include back-image($size: 1rem auto);
}
@mixin angle-up($color: $white) {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10' fill='none'><path stroke='#{$color}' d='M0.999999 9L8 1L15 9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>"));
    @include back-image($size: 1rem auto);
}
@mixin circle-angle($backcolor: $secondary, $size: 2.5rem) {
    @include flex();
    width: $size;
    height: $size;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: $backcolor;
}
