.acceptance {
    display: flex;
    flex-wrap: wrap;
    @include back-color(var(--bs-primary));
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 1rem;

    &-header {
        @extend h5;
    }

    &-body {
        flex: 1 0 auto;
        width: 100%;
        font-weight: $font-weight-bold;
        padding: 1.5rem;

        & .contacts-item {
            display: flex;
            align-items: flex-start;
            @extend .mt-3;

            & .svg-icon {
                flex: 0 0 auto;
                width: 2.5rem;
                height: 2.5rem;
                margin-right: 1rem;
            }

            & p {
                @extend .mb-1;
            }

            & a {
                @extend %unstyled;
            }
        }
    }

    &-map {
        flex: 1 0 auto;
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        &-body,
        &-map {
            width: 50%;
        }
    }

    @include media-breakpoint-up(xl) {
        border-radius: $border-radius-lg;
    }

    @include media-breakpoint-up(xxl) {
        &-body {
            padding: 2.5rem;
        }
    }

    @include media-breakpoint-only(md) {
        &-body {
            font-size: $font-size-sm;
        }
    }

    @include media-breakpoint-down(sm) {
        &-header {
            font-size: 1.125rem;
        }

        &-body {
            font-size: $font-size-sm;
        }

        & .contacts-item {
            & .svg-icon {
                width: 1.75rem;
                height: 1.75rem;
                margin-right: .75rem;
            }
        }
    }
}
