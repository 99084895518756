.collapsible {
    &__item {
        display: flex;
        flex-direction: column;
        @include padding-y(1rem);
        @extend .border-bottom;
    }

    &-head {
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;

        & .title {
            flex: 1 0 0%;
            @extend h5;
            margin-bottom: 0;
            margin-right: 1rem;
        }

        &:after {
            content: '';
            @include circle-angle(var(--bs-secondary));
            @include angle-down();
            @include transition($btn-transition);
            flex: 0 0 auto;
            align-self: flex-start;
        }

        &.active {
            &:after {
                @include angle-up();
            }
        }

        &:hover {
            &:after {
                background-color: var(--bs-secondary-hover);
            }
        }
    }

    &-body {
        padding-top: 1rem;
        @extend .pe-md-5;
        @extend .pe-lg-6;
    }

    @include media-breakpoint-up(xl) {
        &__item {
            @include padding-y(1.25rem);
        }

        &-head {
            & .title {
                font-size: 1.25em;
            }
        }

        &-body {
            padding-top: 1.25rem;
        }
    }

    @include media-breakpoint-down(md) {
        &-head {
            & .title {
                font-size: 1.125rem;
            }

            &:after {
                width: 2rem;
                height: 2rem;
                background-size: .75rem auto !important;
            }
        }
    }
}
